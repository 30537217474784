import Form from "../components/formcomponent"
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionTitle from "../components/sectiontitle";
import React from "react";


const Press = () => {
  return (
    <Layout>
      <SEO title="リリース受付・取材依頼" keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
      <SectionTitle value="リリース受付・取材依頼" button="記事一覧へ" visible="off" link="/" />
      <Form appName="press" title="リリース受付・取材依頼"/>
    </Layout>
  )
}

export default Press